/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable block-scoped-var */
import { format, addMinutes } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const MISSING_VET_ID = 1;
const MISSING_VET_COLOR = '#DD4B39';

function generateRandomColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `66${value.toString(16)}`.substr(-2);
  }
  return color;
}

const isSlotBooked = slot => {
  const orderStatuses = [
    'checkout_complete',
    'submitted_for_settlement',
    'dogbuddy',
    'insurance',
    'free',
    'subscription',
  ];

  return orderStatuses.includes(slot.orderStatus);
};

const isJournalFinished = slot => {
  return !!slot.journalStatus;
};

const determineSlotBackgroundColor = slot => {
  const isMissingVet = !slot.user_id;
  const isBooked = isSlotBooked(slot);
  const isFinished = isJournalFinished(slot);

  let backgroundColor = '#C38F69';

  if (isBooked) backgroundColor = '#0061FF';
  if (isFinished) backgroundColor = '#507D2A';
  if (isMissingVet) backgroundColor = '#DD4B39';

  return backgroundColor;
};

const determineCustomerName = slot => {
  if (slot.customerName) return slot.customerName;
  if (slot.customerFirstName || slot.customerLastName)
    return `${slot.customerFirstName} ${slot.customerLastName}`;
  return 'Unknown name';
};

const determineShiftBackgroundColor = shift => {
  const service = shift.service.key;

  switch (service) {
    case 'veterinary':
      return 'rgba(0, 97, 255, .8)';

    case 'dogbuddy':
      return 'rgba(173, 121, 153, .8)';

    case 'behavior_counseling':
      return 'rgba(45, 45, 214, .8)';

    case 'dentistry':
      return 'rgba(255, 30, 53, .8)';

    case 'rehabilitation_wellness':
      return 'rgba(255, 102, 0, .8)';

    case 'prescription_drugs':
      return 'rgba(255, 255, 71, .8)';

    case 'nutritional_counseling':
      return 'rgba(56, 48, 46, .8)';

    case 'health_control':
      return 'rgba(120, 133, 133, .8)';

    case 'free-welcome-call':
      return 'rgba(111, 104, 102, .8)';

    case 'veterinary_chat':
      return 'rgba(0, 179, 39, .8)';

    case 'healthy_pet_checkup':
      return 'rgba(31, 122, 140, .8)';

    default:
      return 'rgba(114, 0, 255, .8)';
  }
};

const determineSlotSize = slot => {
  const hasSlotId = !!slot.slot_id;
  if (!hasSlotId) return 15;
  return +slot.slotDuration + slot.slotMargin;
};

const mapSlotToScheduler = slot => {
  const bookingTime = new Date(slot.booking_datetime);
  const slotSize = determineSlotSize(slot);
  const endTime = addMinutes(new Date(slot.booking_datetime), slotSize);
  const backgroundColor = determineSlotBackgroundColor(slot);
  const isMissingVet = !slot.user_id;

  return {
    appointment_id: slot.appointment_id,
    backgroundColor,
    className: 'slot',
    end: format(new Date(endTime), 'yyyy-MM-dd HH:mm:ss'),
    id: slot.id,
    overlap: true,
    resourceId: isMissingVet ? MISSING_VET_ID : slot.user_id,
    slotSize,
    start: format(new Date(bookingTime), 'yyyy-MM-dd HH:mm:ss'),
    status: 1,
    title: format(new Date(bookingTime), 'HH:mm'),
    type: 'slot',
    booked: isSlotBooked(slot),
  };
};

const mapShiftsToScheduler = (shifts, vetId = false) => {
  return shifts.map(shift => {
    return {
      //   backgroundColor: '#3687bd',
      backgroundColor: generateRandomColor(`${shift.user.id} + 1`),
      className: 'ordinary shift',
      end: shift.end,
      id: shift.id,
      overlap: true,
      quinyx_id: shift.external_service_id,
      resourceId: shift.user_id,
      start: shift.start,
      title: vetId
        ? `${format(new Date(shift.start), 'HH:mm')} - ${format(
            new Date(shift.end),
            'HH:mm'
          )}`
        : '',
      type: 'shift',
      vet: shift.user,
      display: vetId ? 'auto' : 'background',
      timeZone: shift.timezone,
    };
  });
};

const mapShiftsToTimeline = shifts => {
  return shifts.map(shift => {
    const backgroundColor = determineShiftBackgroundColor(shift);
    return {
      start: format(
        zonedTimeToUtc(new Date(shift.start), shift.timezone),
        'yyyy-MM-dd HH:mm:ss'
      ),
      end: format(
        zonedTimeToUtc(new Date(shift.end), shift.timezone),
        'yyyy-MM-dd HH:mm:ss'
      ),
      backgroundColor,
    };
    //   backgroundColor: '#101c4e',
  });
};

const mapBookingsToTimeline = bookings => {
  return bookings.map(slot => {
    const slotSize = determineSlotSize(slot);
    const backgroundColor = determineSlotBackgroundColor(slot);
    const isBooked = isSlotBooked(slot);
    const customer = determineCustomerName(slot);

    const startTime = format(
      zonedTimeToUtc(new Date(slot.booking_datetime), slot.timezone),
      'yyyy-MM-dd HH:mm:ss'
    );
    const endTime = format(
      addMinutes(
        zonedTimeToUtc(new Date(slot.booking_datetime), slot.timezone),
        slotSize
      ),
      'yyyy-MM-dd HH:mm:ss'
    );
    return {
      start: startTime,
      end: endTime,
      title: isBooked ? slot.animalName : 'Pending',
      appointmentID: slot.appointment_id,
      animalName: slot.animalName,
      user: customer,
      description: slot.description,
      animalType: slot.animalType,
      booked: isBooked,
      backgroundColor,
    };
  });
};

const mapUserToScheduler = (user, index) => {
  if (!user) {
    return {
      backgroundColor: MISSING_VET_COLOR,
      id: MISSING_VET_ID,
      title: 'MISSING VETERINARIAN',
      order: 0,
    };
  }

  const displayName =
    user.display_name || user.name || `${user.first_name} ${user.last_name}`;

  return {
    backgroundColor: '#26bfd3',
    id: user.id,
    title: displayName,
    order: index,
  };
};

const APPOINTMENT_STATUS = {
  pending: '1',
  insurance: 'insurance',
  free: 'free',
  checkout_complete: 'checkout_complete',
};

export {
  mapShiftsToScheduler,
  mapSlotToScheduler,
  mapUserToScheduler,
  generateRandomColor,
  determineSlotSize,
  mapShiftsToTimeline,
  mapBookingsToTimeline,
  isSlotBooked,
  APPOINTMENT_STATUS,
};
